import {
  IAutoCollectActiveFields,
  IAutoCollectPrefilledFields,
  ICustomEmailContent,
  IEntity,
  IOwner,
  SubmissionStatuses,
  UserType,
} from ".";

export interface IHttpsCallableResponse {
  success: boolean;
  message: string;
  location?: string;
}

export interface IUserInviteRequest {
  to: string;
  orgId: string;
  orgName: string;
  tempUserId: string;
  senderEmail: string;
}

export interface IUserInviteResponse extends IHttpsCallableResponse {
  message: string;
}

export interface IReplaceTempUserIdRequest {
  tempUserId: string;
  userId: string;
  orgId: string;
  email: string;
  userDisplayName: string;
}

export interface IReplaceTempUserIdResponse extends IHttpsCallableResponse {
  message: string;
}

export interface ICheckIfEmailInUseRequest {
  email: string;
}

export interface ICheckIfEmailInUseResponse extends IHttpsCallableResponse {
  isEmailInUse: boolean;
}

export interface ICheckActiveInvitationRequest {
  email: string;
}

export interface ICheckActiveInvitationResponse extends IHttpsCallableResponse {
  doesInvitationExist: boolean;
}

export interface IValidateUserInviteRequest {
  tempUserId: string;
  orgId: string;
}

export interface IValidateUserInviteResponse extends IHttpsCallableResponse {
  message: string;
}

export interface IVerifyDobRequest {
  ownerId: string;
  entityId: string;
  groupId: string;
  orgId: string;
  dob: string;
}

export interface IVerifyDobResponse {
  success: boolean;
  message: IVerifyDobResponseOwner | string;
}

export type IVerifyDobResponseOwner = IOwner;

export interface IMirrorOwnerRequest {
  orgId: string;
  ownerId: string;
  entityId: string;
}

export interface ISendImageCollectionEmailRequest {
  orgId: string;
  entityName?: string;
  ownerIds: string[];
}

export interface ISendImageCollectionEmailResponse
  extends IHttpsCallableResponse {
  message: string;
  owners: IOwner[];
}

export interface IDecodeImageCollectionJwtRequest {
  token: string;
}

export interface ISendAutoCollectEmailRequest {
  userType: UserType;
  entityId?: string;
  orgId?: string;
  groupId?: string;
  ownerId: string;
  email: string;
  name: string;
  legalName: string;
  senderName: string;
  senderPhone?: string;
  senderEmail?: string;
  customEmailContent?: ICustomEmailContent | null;
}

export interface IDecodeImageCollectionJwtResponse
  extends IHttpsCallableResponse {
  message: string;
  decoded: ISendAutoCollectEmailRequest;
  fieldStatuses: IAutoCollectActiveFields;
  prefilledFields: IAutoCollectPrefilledFields;
}

export interface IUploadImageRequest {
  token?: string;
  image: string;
  mediaType: string;
  userType: UserType | null;
  orgId?: string;
  // entityId is required if the userType is "secure-file"
  entityId?: string;
  ownerId?: string;
}

export interface IValidateBulkEntityFile {
  orgId: string;
  groupId: string;
  mediaType: string;
  file: string;
}

export interface IValidateBulkEntityResponse extends IHttpsCallableResponse {
  entities: IValidatedBulkUploadEntity[];
}

export type IBulkUploadEntity = Pick<
  IEntity,
  | "legalName"
  | "tradeNames"
  | "taxIdType"
  | "taxId"
  | "stateOfFormation"
  | "address"
>;

export type IBulkUploadEntityValidationMessages = {
  [Key in Exclude<keyof IBulkUploadEntity, "address">]?: string[];
} & {
  address?: {
    [Key in keyof IBulkUploadEntity["address"]]?: string[];
  };
};

export interface IValidatedBulkUploadEntity {
  entity: IBulkUploadEntity | null;
  errors: IBulkUploadEntityValidationMessages | null;
  warns: IBulkUploadEntityValidationMessages | null;
}

export interface IBulkEntityQueueItem extends IBulkUploadEntity {
  id: string;
  groupId: string;
  userIds: string[];
}

export interface IUploadBulkEntities {
  userId: string;
  orgId: string;
  groupId: string;
  entities: IValidatedBulkUploadEntity[];
}

export interface IManualIdUploadDialogDataOutput {
  image: string;
  mediaType: string;
}

export interface IMoveEntityGroupRequest {
  orgId: string;
  entityId: string;
  entityLegalName: string;
  fromGroupId: string | null;
  toGroupId: string;
}

export interface ISendSecureFileTransferEmailRequest {
  email: string;
  ownerName: string;
  entityId: string;
  groupId: string;
  entityName: string;
  orgId: string;
  orgName: string;
}

export interface IRevokeSecureFileInviteRequest {
  orgId: string;
  entityId: string;
  groupId: string;
}

export interface ICanBeHandedOffInstantlyRequest {
  email: string
}

export interface ICanBeHandedOffInstantlyResponse extends IHttpsCallableResponse {
  userId?: string;
}

export interface ISendInstantlyTransferEmailRequest {
  orgName: string;
  email: string;
  displayName: string;
  entityId: string;
  entityName: string;
}

export interface IValidateSecureFileInvitationTokenResponse
  extends IHttpsCallableResponse {
  payload: ISendSecureFileTransferEmailRequest;
}

export type ISelfBillBulkSubscription =
  | "SecureProSelfBillSubscriptionBulk50"
  | "SecureProSelfBillSubscriptionBulk60"
  | "SecureProSelfBillSubscriptionBulk70"
  | "SecureProSelfBillSubscriptionBulk80";

export type ICheckoutType =
  | "SecureProSetupFee"
  | "SecureFileExemptionCertificate"
  | "SecureFileEntitySubscription"
  | "SecureProSelfBillSubscription"
  | "SecureProSelfBillSubscriptionBulk50"
  | "SecureProSelfBillSubscriptionBulk60"
  | "SecureProSelfBillSubscriptionBulk70"
  | "SecureProSelfBillSubscriptionBulk80"
  | "SecureFileEntitySubscriptionAndSetupFee";

export type ISelfBillCheckoutType =
  | "SecureProSelfBillSubscription"
  | ISelfBillBulkSubscription;

export interface IPaymentSession {
  session_id: string;
  client_secret: string;
}

export interface ICheckStripeSessionStatusResponse
  extends IHttpsCallableResponse {
  payload: {
    status: "open" | "complete" | "expired";
    paymentStatus: "paid" | "unpaid" | "no_payment_required";
  };
}

export interface ICreateReportRequest {
  entityData: IEntity | undefined;
  currentDate: string;
}

export interface ICreateReportResponse extends IHttpsCallableResponse {
  htmlTemplate: string;
}

export interface IListPaymentMethodsResponse extends IHttpsCallableResponse {
  data: [];
}

export interface ICreateSubscriptionResponse extends IHttpsCallableResponse {
  subscriptionId: string;
  payload: {
    status:
      | "active"
      | "canceled"
      | "incomplete"
      | "incomplete_expired"
      | "past_due"
      | "paused"
      | "trialing"
      | "unpaid";
  };
}

export interface IInvoiceResponse {
  id: string;
  period_end: number;
  total: number;
  invoice_pdf: string | null;
  hosted_invoice_url: string | null;
  number: string | null;
  receipt_number: string | null;
  subscription_details: {
    metadata: {
      entityName?: string;
      entityId?: string;
    };
  };
  metadata: {
    entityName?: string;
    legalName?: string;
    entityId?: string;
    orgId?: string;
  };
}

export interface IInvoice {
  date: string;
  amount: string;
  entityName: string;
  invoiceId: string;
}

export interface IListInvoicesResponse extends IHttpsCallableResponse {
  success: boolean;
  message: string;
  data: IInvoiceResponse[];
}

export interface IUpsertProEntityRequest {
  orgId: string;
  newGroupId: string;
  existingGroupId?: string;
  entityId: string;
  entity: IEntity;
  ownerImages: { [ownerId: string]: IUploadImageRequest };
  ownersUpdated: boolean;
}

export interface IUpsertFileEntityRequest {
  entityId: string;
  entity: IEntity;
  ownersUpdated: boolean;
}

export interface ICheckoutSessionRequest {
  checkoutType: ICheckoutType;
  entityId: string | null;
  couponCode: string | null;
  userType: UserType | null;
  entityName: string | null;
}

export interface IBoirStatusResponse {
  processId: string;
  submissionStatus: SubmissionStatuses;
  errors?: {
    ErrorText?: string;
    ErrorCode?: string;
  }[];
  validationErrors?: {
    errorMessage?: string;
  }[];
  fincenID?: string;
  BOIRID?: string;
}
