import { ISelfBillBulkSubscription } from "./requests.interface";

export interface ISubscriptionEntity {
  orgId: string;
  selfBillCheckoutType:
  | "SecureProSelfBillSubscription"
  | ISelfBillBulkSubscription;
  createdDate: Date | null;
  entityDeleted?: boolean;
  legalName: string;
  entityId: string;
  invoiceId?: string;
}

export interface ISubscriptionEntityMap {
  [entityId: string]: ISubscriptionEntity;
}

export interface ISubscriptionMonthlyBucket {
  stripeSubscriptionId?: string;
  itemId?: string;
  entities?: ISubscriptionEntityMap; // sub-collection
}

export interface ISubscriptionMonthlyBucketMap {
  [date: string]: ISubscriptionMonthlyBucket;
}

export interface ISubscriptionBucket {
  orgIds: string[];
  bulkPrepayAmount: number | null;
  subscriptions?: ISubscriptionMonthlyBucketMap; // sub-collection
}

export interface ISubscriptionBucketMap {
  [stripeCustomerId: string]: ISubscriptionBucket;
}
