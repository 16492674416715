import { Pipe, PipeTransform } from '@angular/core';
import { ICheckboxFilterableTableColumn, IFilterableTableColumn } from '../components/filterable-table/filterable-table.component';

@Pipe({
  name: 'isCheckboxFilter'
})
export class IsCheckboxFilterPipe implements PipeTransform {

  transform<T>(column: IFilterableTableColumn<T>): column is ICheckboxFilterableTableColumn<T> {
    return this.isCheckboxFilter(column);
  }

  private isCheckboxFilter<T>(column: IFilterableTableColumn<T>): column is ICheckboxFilterableTableColumn<T> {
    return column.filterType === 'checkbox';
  }
}