import { Injectable, inject } from "@angular/core";
import { Functions } from "@angular/fire/functions";
import { httpsCallable } from "firebase/functions";
import {
  EntityStatuses,
  IBoirQueueItem,
  IBoirStatusResponse,
  IEntity,
  IEntitySubmitConsent,
  IEntityUpdates, IWebBoirFilingQueueItem,
  SubmissionStatuses,
} from "../interfaces";
import { MatDialog } from "@angular/material/dialog";
import {
  ActionDialogComponent,
  ActionDialogData,
} from "../components/action-dialog/action-dialog.component";
import { Store } from "@ngxs/store";
import { UserStateModel } from "src/app/state/user-state/user-model.interface";
import { SecureFileStateModel } from "src/app/state/secure-file-state/secure-file-model.interface";
import { SecureProStateModel } from "src/app/state/secure-pro-state/secure-pro-model.interface";
import { SecureFile } from "src/app/state/secure-file-state/secure-file.actions";
import { Entity } from "src/app/state/secure-pro-state/secure-pro.actions";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { Firestore } from "@angular/fire/firestore";
import { emailRegex } from "../utils/constants";
import { MatSnackBar } from "@angular/material/snack-bar";
import { v4 } from "uuid";

interface AccessToken {
  token: string;
  issuedOn: Date | null; // lasts for 1 hour
}

@Injectable({
  providedIn: "root",
})
export class BoirFilingsService {
  private functions: Functions = inject(Functions);
  private accessToken: AccessToken = {
    token: "",
    issuedOn: null,
  };
  private dialog: MatDialog = inject(MatDialog);
  private store = inject(Store);
  private firestore = inject(Firestore);
  private snackbar = inject(MatSnackBar);

  async enqueueBOIR(
    updatedEntity: IEntity,
    consentData: IEntitySubmitConsent,
    orgId: string | null
  ) {
    try {
      const processId = await this.initiateBOIR();

      if (!processId) {
        const error =
          "Could not get process ID from FinCEN API. Please try again.";
        this.snackbar.open(error, "Dismiss");
        throw new Error(error);
      }

      const boirFilingQueueDoc = doc(
        this.firestore,
        "boirFilingQueue",
        processId
      );

      if (!boirFilingQueueDoc) {
        const error = "Could not enqueue BOIR filing";
        this.snackbar.open(error, "Dismiss");
        throw new Error(error);
      }

      const queueItem: IBoirQueueItem = {
        entity: updatedEntity,
        consentData,
        submittedSuccessfully: false,
        createdAt: new Date(),
        processId,
        xmlUploadResponse: "",
        attachmentUploadResponses: [],
        orgId,
      };

      await setDoc(boirFilingQueueDoc, queueItem, { merge: true });

      await this.updateEntityStatus(
        updatedEntity.id,
        "e-Filing Pending",
        orgId
      );

      return processId;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  async enqueueWebBOIRFiling(
    entityId: string,
    orgId: string | null = null,
    submittedSuccessfully = false
  ): Promise<string | null> {
    try {
      if (!entityId) {
        throw new Error('Entity ID is required');
      }

      const queueItem: IWebBoirFilingQueueItem = {
        entityId,
        orgId,
        submittedSuccessfully,
      }

      const filingId = v4();

      const webBoirFilingQueueDoc = doc(
        this.firestore,
        "webBoirFilingQueue",
        filingId
      );

      await setDoc(webBoirFilingQueueDoc, queueItem, { merge: true });

      return filingId;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  private async updateEntityStatus(
    entityId: string,
    status: EntityStatuses,
    orgId: string | null
  ) {
    try {
      const db = this.firestore;
      const entityRef = orgId
        ? doc(db, "organization", orgId, "entityList", entityId)
        : doc(db, "secureFileEntities", entityId);

      await updateDoc(entityRef, {
        status,
      });
    } catch (error) {
      console.error("Could not update entity status: ", error);
    }
  }

  private async initiateBOIR() {
    try {
      const accessToken = await this.checkAccessToken();

      const initiateBOIRCallable = httpsCallable<
        { accessToken: string },
        string
      >(this.functions, "initiateBOIR");

      const { data } = await initiateBOIRCallable({
        accessToken: accessToken.token,
      });

      if (!data) {
        throw new Error("Could not retrieve BOIR process ID");
      }

      return data;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  async checkSubmissionStatus(processId: string) {
    const accessToken = await this.checkAccessToken();

    const checkSubmissionStatusCallable = httpsCallable<
      { processId: string; accessToken: string },
      IBoirStatusResponse
    >(this.functions, "checkSubmissionStatus");

    const { data } = await checkSubmissionStatusCallable({
      processId,
      accessToken: accessToken.token,
    });

    return data;
  }

  async getTranscript(processId: string, entityId: string) {
    const transcriptFromStorage = await this.getTranscriptFromStorage(
      processId,
      entityId
    );

    if (transcriptFromStorage) {
      return transcriptFromStorage;
    }

    const accessToken = await this.checkAccessToken();

    const getTranscriptCallable = httpsCallable<
      { entityId: string; processId: string; accessToken: string },
      { pdfBinary: string; status: { submissionStatus: string } }
    >(this.functions, "getTranscript");

    const { data } = await getTranscriptCallable({
      entityId,
      processId,
      accessToken: accessToken.token,
    });

    return data;
  }

  private async getTranscriptFromStorage(processId: string, entityId: string) {
    try {
      const getTranscriptCallable = httpsCallable<
        { entityId: string; processId: string },
        { pdfBinary: string }
      >(this.functions, "getTranscriptFromStorage");

      const { data } = await getTranscriptCallable({
        entityId,
        processId,
      });
      return data;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  private async checkAccessToken() {
    if (
      this.accessToken.token === "" ||
      this.accessToken.issuedOn === null ||
      this.accessToken.issuedOn.getTime() < Date.now() - 3600000
    ) {
      return this.obtainAccessToken();
    } else {
      return this.accessToken;
    }
  }

  private async obtainAccessToken() {
    const obtainAccessTokenCallable = httpsCallable<null, string>(
      this.functions,
      "obtainAccessTokenCallable"
    );

    const { data } = await obtainAccessTokenCallable();

    this.accessToken = {
      token: data,
      issuedOn: new Date(),
    };

    return this.accessToken;
  }

  public entityIsValid(consentData: IEntitySubmitConsent) {
    const dialogData: ActionDialogData = {
      title: "Validation Error",
      message: "",
      positiveAction: false,
      positiveActionButtonText: "Return",
      negativeActionButtonText: "Cancel",
    };

    if (!this.signatoryIsValid(consentData)) {
      this.dialog.open(ActionDialogComponent, {
        width: "350px",
        data: {
          ...dialogData,
          message:
            'Signatory information must include valid email, first name, & last name. If your owner is an entity, please select "Entity is signatory" and try again.',
        },
      });

      return false;
    }

    return true;
  }

  private signatoryIsValid(consentData: IEntitySubmitConsent) {
    return (
      emailRegex.test(consentData.email) &&
      consentData.firstName &&
      consentData.lastName
    );
  }

  async getSecureProEntity(entityId: string) {
    const org = this.store.selectSnapshot(
      (state) => (state.securePro as SecureProStateModel)?.org
    );
    return (await org.fetchEntityById(entityId)) as IEntity;
  }

  async updateEntity(entityId: string, entityUpdates: IEntityUpdates) {
    const userType = this.store.selectSnapshot(
      (state) => (state.user as UserStateModel).userType
    );

    const originalEntity =
      userType === "secure-pro"
        ? await this.getSecureProEntity(entityId)
        : this.store.selectSnapshot((state) =>
            (state.secureFile as SecureFileStateModel).entities.find(
              (entity) => entity.id === entityId
            )
          );

    const entity = {
      ...(originalEntity as IEntity),
      ...entityUpdates,
    };

    this.updateEntityByType(entityId, entity);
  }

  private async updateEntityByType(entityId: string, entity?: IEntity) {
    const userType = this.store.selectSnapshot(
      (state) => (state.user as UserStateModel).userType
    );
    if (!entity) return;

    switch (userType) {
      case "secure-file":
        this.store.dispatch(
          new SecureFile.UpsertEntity(entityId, entity, false)
        );
        break;
      case "secure-pro":
        this.store.dispatch(
          new Entity.Upsert(
            entity?.groupId as string,
            entityId,
            entity,
            {},
            false
          )
        );
        break;
    }
  }

  apiStatusToSubmissionStatus(apiStatus: SubmissionStatuses): EntityStatuses {
    switch (apiStatus) {
      case "submission_accepted":
        return "e-Filing Accepted";
      case "submission_pending":
        return "e-Filing Pending";
      case "submission_rejected":
      case "submission_validation_failed":
        return "e-Filing Rejected";
      default:
        return "e-Filing Pending";
    }
  }
}
